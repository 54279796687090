import { ApiService } from '@app/core/abstracts/api.service'
import { Observable } from 'rxjs'
import { AdditionalPage } from 'src/app/features/contract/shared/types'

import { LangService } from './lang.service'

import { Injectable, inject } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AdditionalPageService extends ApiService {
  private langService: LangService = inject(LangService)

  get(slug: string): Observable<AdditionalPage> {
    return this.getPage<any>(`/front/additional-page`, slug, (itemData: AdditionalPage) =>
      AdditionalPage.fromJson<AdditionalPage>(itemData, this.langService),
    )
  }
}
