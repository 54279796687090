import { adminAuthGuard } from './core/guards/admin-auth.guard'
import { AuthGuard } from './core/guards/auth.guard'
import { additionalPageResolver } from './core/resolvers/additional-page.resolver'
import { contactsListResolver } from './core/resolvers/contancts.resolver'
import { deviceResolver } from './core/resolvers/device.resolver'
import { faqResolver } from './core/resolvers/faq.resolver'
import { newsListResolver } from './core/resolvers/news.resolver'
import {
  becomePartnersResolver,
  homeResolver,
  serviceBuyStorageResolver,
  serviceCheckCertificateResolver,
  servicePointsResolver,
  serviceRefreshCertificateResolver,
  serviceSignatureCertificateResolver,
} from './core/resolvers/page.resolver'
import { BreadcrumbService } from './core/services/breadcrumb.service'

import { Routes } from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    loadComponent: async () => (await import('./features/main')).MainComponent,
    data: { animation: 'main', take: 5 },
    resolve: { page: homeResolver, news: newsListResolver, faq: faqResolver },
  },
  { path: 'ui-kit', loadComponent: async () => (await import('./ui-kit')).UiKitComponent, data: { animation: 'ui-kit' } },
  {
    path: 'profile',
    loadComponent: async () => (await import('./features/user/components/user-profile')).UserProfileComponent,
    data: { animation: 'user-profile' },
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/profile',
    loadComponent: async () => (await import('./features/admin/components/admin-profile')).AdminProfileComponent,
    data: { animation: 'admin-profile' },
    canActivate: [adminAuthGuard],
  },
  {
    path: 'user',
    loadComponent: async () => (await import('./features/user')).UserComponent,
    data: { animation: 'Main' },
    loadChildren: () => import('./features/user/user.routes').then((r) => r.USER_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadComponent: async () => (await import('./features/admin')).AdminComponent,
    data: { animation: 'Admin' },
    loadChildren: () => import('./features/admin/admin.routes').then((r) => r.ADMIN_ROUTES),
    canActivate: [adminAuthGuard],
  },

  {
    path: 'devices/:slug',
    loadComponent: async () => (await import('./features/devices')).DevicesComponent,
    data: { animation: 'devices' },
    resolve: { data: deviceResolver },
  },
  {
    path: 'news',
    loadComponent: async () => (await import('./features/news')).NewsComponent,
    data: { animation: 'news' },
    loadChildren: async () => (await import('./features/news/news.routes')).NEWS_ROUTES,
  },
  {
    path: 'become_partner',
    loadComponent: async () => (await import('./features/partners')).PartnersComponent,
    data: { animation: 'partners' },
    resolve: { page: becomePartnersResolver },
  },
  {
    path: 'service_points',
    loadComponent: async () => (await import('./features/contacts')).ContactsComponent,
    data: { animation: 'contacts' },
    resolve: { data: contactsListResolver, page: servicePointsResolver },
  },
  {
    path: 'service_buy_storage',
    loadComponent: async () => (await import('./features/order')).OrderComponent,
    data: { animation: 'order' },
    resolve: { page: serviceBuyStorageResolver },
  },
  { path: 'login', loadComponent: async () => (await import('./features/login')).LoginComponent, data: { animation: 'login' } },
  { path: 'diia-sign', loadComponent: async () => (await import('./features/diia-sign')).DiiaSignComponent, data: { animation: 'login' } },
  {
    path: 'faq',
    loadComponent: async () => (await import('./features/faq')).FaqComponent,
    data: { animation: 'faq' },
    loadChildren: () => import('./features/faq/faq.routes').then((r) => r.FAQ_ROUTES),
    providers: [BreadcrumbService],
  },
  { path: 'software', loadComponent: async () => (await import('./features/software')).SoftwareComponent, data: { animation: 'software' } },
  {
    path: 'contracts',
    loadComponent: async () => (await import('./features/contracts')).ContractsComponent,
    data: { animation: 'contracts' },
  },
  {
    path: 'reglament',
    loadComponent: async () => (await import('./features/reglament')).ReglamentComponent,
    data: { animation: 'reglament' },
  },
  {
    path: 'digital-signature',
    loadComponent: async () => (await import('./features/digital-signature')).DigitalSignatureComponent,
    data: { animation: 'digital-signature' },
  },
  {
    path: 'service_search_certificate',
    loadComponent: async () => (await import('./features/search-certificates')).SearchCertificatesComponent,
    loadChildren: async () => (await import('./features/search-certificates/search-certificates.routes')).SEARCH_CERTIFICATES_ROUTES,
    data: { animation: 'search-certificates' },
  },
  {
    path: 'change-status',
    loadComponent: async () => (await import('./features/change-status')).ChangeStatusComponent,
    data: { animation: 'change-status' },
  },
  {
    path: 'partnerships',
    loadComponent: async () => (await import('./features/partnerships')).PartnershipsComponent,
    data: { animation: 'partnerships' },
  },
  {
    path: 'service_refresh_certificate',
    loadComponent: async () => (await import('./features/update-certificate')).UpdateCertificateComponent,
    data: { animation: 'update-certificate' },
    resolve: { page: serviceRefreshCertificateResolver },
  },
  {
    path: 'service_signature_certificate',
    loadComponent: async () => (await import('./features/sign-document')).SignDocumentComponent,
    data: { animation: 'sign-document' },
    resolve: { page: serviceSignatureCertificateResolver },
  },
  {
    path: 'service_check_certificate',
    loadComponent: async () => (await import('./features/check-sign')).CheckSignComponent,
    data: { animation: 'check-sign' },
    resolve: { page: serviceCheckCertificateResolver },
  },
  {
    path: 'add/:slug',
    loadComponent: async () => (await import('./features/contract')).ContractComponent,
    data: { animation: 'contract' },
    resolve: { data: additionalPageResolver },
  },
  {
    path: ':slug',
    loadComponent: async () => (await import('./features/additional-page')).AdditionalPageComponent,
    data: { animation: 'dynamic-page' },
    resolve: { page: additionalPageResolver },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
]
