import { NavigationService } from '@app/core/services/navigation.service'
import { PageService } from '@app/core/services/page.service'
import { Observable, filter, map, startWith } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { NavigationEnd, Router, RouterLink } from '@angular/router'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, SvgIconsModule, NgIf, AsyncPipe, NgFor, RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  svgIcons = SvgIcon

  isLargeFooter$: Observable<boolean> = this.router.events.pipe(
    startWith(new NavigationEnd(0, this.router.url, this.router.url)),
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => !event.url.includes('admin') && !event.url.includes('user')),
  )

  footerItems$: Observable<NavigationItem[]> = this.navigationService.navigationItems$.pipe(
    map((items) => items.filter((item) => !!item.items?.length)),
  )

  singleItems: Observable<NavigationItem[]> = this.navigationService.additionalLinks$
  page$ = this.pageService.commonTranslations$.pipe(map((translations) => translations['footer']))

  socialIcons$ = this.page$.pipe(
    map((page) => {
      return [
        { icon: SvgIcon.FB, url: page.translation.data.fbLink },
        { icon: SvgIcon.TWITTER, url: page.translation.data.twitterLink },
        { icon: SvgIcon.INSTAGRAM, url: page.translation.data.instagramLink },
        { icon: SvgIcon.TELEGRAM, url: page.translation.data.telegramLink },
      ]
    }),
  )

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private pageService: PageService,
  ) {}
}
