<div *ngIf="page$ | async as page" class="bg-background-black text-background-white">
  <div class="wrapper">
    <ng-container *ngIf="isLargeFooter$ | async">
      <div class="l-footer hidden md:flex w-full justify-between px-16 pt-60 pb-60 gap-40" role="contentinfo">
        <div *ngFor="let col of footerItems$ | async" class="flex-1">
          <h2 class="font-500">{{ col.label }}</h2>
          <div class="flex flex-col mt-24 gap-16">
            <a
              *ngFor="let link of col.items"
              [routerLink]="link.url"
              class="font-500 opacity-50 hover:underline"
              [attr.aria-label]="link.label"
            >
              <h6>{{ link.label }}</h6>
            </a>
          </div>
        </div>
      </div>
      <div class="py-40 px-16 hidden md:flex w-full justify-between">
        <div class="flex flex-col gap-16">
          <h2 class="font-500" [innerHTML]="page?.translation?.data['additionalLinks']"></h2>
          <a
            *ngFor="let link of singleItems | async"
            [href]="link.url"
            target="_blank"
            class="font-500 hover:underline"
            [attr.aria-label]="link.label"
          >
            {{ link.label }}
          </a>
        </div>
        <div class="flex flex-col gap-10">
          <span [innerHTML]="page?.translation?.data['socialTitle']"></span>
          <div class="social-wrapper flex gap-12">
            <ng-container *ngIf="socialIcons$ | async as socialIcons">
              <a *ngFor="let link of socialIcons" [href]="link.url" target="_blank" [attr.aria-label]="link.icon">
                <mat-icon [svgIcon]="link.icon"></mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <footer class="footer flex items-center w-full gap-10 text-background-white md:px-32" role="contentinfo">
      <div class="footer__logs">
        <div class="flex items-center gap-10">
          <mat-icon [svgIcon]="svgIcons.EMBLEM"></mat-icon>
          <mat-icon [svgIcon]="svgIcons.LOGO_WHITE"></mat-icon>
        </div>
      </div>
      <div class="footer__privacy font-300">
        <p>
          <span [innerHTML]="page?.translation?.data['year']"></span> <br />
          <span [innerHTML]="page?.translation?.data['privacy']"></span>
        </p>
      </div>
      <div class="footer__links md:ml-auto font-400 flex flex-col gap-8">
        <a [href]="page?.translation?.data['addressLink']" target="_blank" [attr.aria-label]="page?.translation?.data['addressTitle']">
          <span [innerHTML]="page?.translation?.data['address']"></span>
          <span [innerHTML]="page?.translation?.data['addressTitle']"></span>
        </a>
        <a [href]="page?.translation?.data['emailLink']" [attr.aria-label]="page?.translation?.data['emailTitle']">
          <span [innerHTML]="page?.translation?.data['emailTitle']"></span>
        </a>
      </div>
    </footer>
  </div>
</div>
