import { TranslatableModel } from 'src/app/core/abstracts/translate-model'

export class AdditionalPage extends TranslatableModel<AdditionalPageTranslationData> {
  id!: number
  slug!: string
}

export interface AdditionalPageTranslationData {
  title: string
  content: string
  scripts: string
}
