import { Observable } from 'rxjs'

import { CenterItem } from './shared/types'

import { Injectable, inject } from '@angular/core'

import { ApiService, BaseHttpParams } from 'src/app/core/abstracts/api.service'
import { ResponseInterface } from 'src/app/core/interfaces/response'
import { LangService } from 'src/app/core/services/lang.service'

@Injectable({
  providedIn: 'root',
})
export class ContactsService extends ApiService {
  private langService = inject(LangService)

  getCenters(params: BaseHttpParams): Observable<ResponseInterface<CenterItem>> {
    return this.getList<CenterItem>('/front/center', params, (itemData: CenterItem) =>
      CenterItem.fromJson<CenterItem>(itemData, this.langService),
    )
  }
}
