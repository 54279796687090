import { AdditionalPageService } from '@app/core/services/additional-page.service'
import { of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router'

import { LoggerService } from 'src/app/core/services/logger.service'

export const additionalPageResolver: ResolveFn<any | null> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('slug')!
  const additionalPageService = inject(AdditionalPageService)
  const router = inject(Router)
  const loggerService = inject(LoggerService)

  return additionalPageService.get(slug).pipe(
    tap((page) => loggerService.log(`additionalPageResolver, slug: ${slug}`, page)),
    catchError(() => {
      router.navigateByUrl('/')

      return of(null)
    }),
  )
}
