import { DevicesService } from '@app/features/devices/devices.service'
import { first } from 'rxjs'

import { inject } from '@angular/core'
import { ResolveFn, Router } from '@angular/router'

export const deviceResolver: ResolveFn<any> = (route) => {
  const deviceService = inject(DevicesService)
  const router = inject(Router)

  const slug = route.paramMap.get('slug')

  if (!slug) {
    router.navigate(['/'])

    return
  }

  return deviceService.getDeviceBySlug(slug).pipe(first())
}
