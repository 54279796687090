import { first, map } from 'rxjs'
import { ContactsService } from 'src/app/features/contacts/contacts.service'
import { CenterItem } from 'src/app/features/contacts/shared/types'

import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

export const contactsListResolver: ResolveFn<CenterItem[]> = () => {
  const contactsService = inject(ContactsService)

  return contactsService.getCenters({ take: 20, skip: 0 }).pipe(
    first(),
    map((resp) => resp.data),
  )
}
