import { MetaModel } from './../../../core/models/meta.models'

import { TranslatableModel } from 'src/app/core/abstracts/translate-model'

export interface ContactItem {
  title: string
  items: ContactSign[]
  id: string
}

export interface ContactSign {
  title: string
  address: string
  schedule?: string
  timeout?: string
  phones?: string[]
  emails?: string[]
  service: string
  note?: string
  id: string
}

export class CenterItem extends TranslatableModel<CenterItemTranslationData> {
  id!: number
  slug!: string
}

export interface CenterItemTranslationData {
  blocks: any
  data: CenterItemTranslationDeepData
}

export interface CenterItemTranslationDeepData {
  blocks: CenterItemTranslationBlock[]
  meta?: MetaModel
  title: string
}

export interface CenterItemTranslationBlock {
  buttonName: string
  buttonUrl: string
  content: string
  title: string
}
